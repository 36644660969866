.flow-main-screen {
    width: 100%;
    height: 80vh;
    background-color: #111111f7;
    border-radius: 0.5rem;
    padding: 0.7rem;
}

.popOut {
    color: #c4c2c2;

    &:hover {
        color: #fff;
        scale: 1.1;
    }
}

.react-flow__attribution {
    display: none;
}
@use "sass:math";

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

// Shades of grey
$white   : #ffffff !default;
$gray-50:  #F9FAFB !default;
$gray-100: #F2F4F6 !default;
$gray-200: #E5E7EB !default;
$gray-300: #D1D5DB !default;
$gray-400: #9CA3AF !default;
$gray-500: #6B7280 !default;
$gray-600: #4B5563 !default;
$gray-700: #374151 !default;
$gray-800: #1F2937 !default;
$gray-900: #111827 !default;
$dark    : $gray-800 !default;
$black   : $gray-900 !default;

// fusv-disable
$grays: ("100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900) !default;
// fusv-enable

// Generic colors
$blue       : #2361ce !default;
$indigo     : #4F46E5 !default;
$purple     : #7C3AED !default;
$pink       : #EF4683 !default;
$red        : #E11D48 !default;
$orange     : #FBA918 !default;
$brown      : #b9a084 !default;
$yellow     : #f3c78e !default;
$yellow-100 : #ffeed6 !default;
$yellow-200 : #f5dbb8 !default;
$green      : #10B981 !default;
$teal       : #1E90FF !default;
$cyan       : #63b1bd !default;
$soft-indigo: #f5e8ff !default;
$soft-green : #2CA58D !default;

// scss-docs-start colors-map
$colors: ("blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "gray": $gray-600,
    "soft": $gray-200,
    "gray-dark": $gray-800) !default;
// scss-docs-end colors-map

// Color scheme
$primary        : #262B40 !default;
$secondary      : #61DAFB !default;
$secondary-alt  : #acebfd !default;
$tertiary       : #1B998B !default;

$success: $green !default;
$info   : $blue !default;
$warning: $orange !default;
$danger : $red !default;
$gray   : $gray-900 !default;
$light  : $gray-400 !default;
$lighten: $gray-300 !default;
$soft   : $gray-200 !default;
$dark   : $dark !default;

// Brands colors
$facebook : #3b5999 !default;
$twitter  : #1da1f2 !default;
$google   : #DB4337 !default;
$instagram: #e4405f !default;
$pinterest: #bd081c !default;
$youtube  : #cd201f !default;
$slack    : #3aaf85 !default;
$dribbble : #ea4c89 !default;
$github   : #222222 !default;
$dropbox  : #1E90FF !default;
$twitch   : #4B367C !default;
$paypal   : #ecb32c !default;
$behance  : #0057ff !default;
$reddit   : #E84422 !default;

// Brand colors
$brand-colors: ("facebook": $facebook,
    "twitter": $twitter,
    "google": $google,
    "instagram": $instagram,
    "pinterest": $pinterest,
    "youtube": $youtube,
    "slack": $slack,
    "dribbble": $dribbble,
    "dropbox": $dropbox,
    "twitch": $twitch,
    "paypal": $paypal,
    "behance": $behance,
    "reddit" : $reddit,
    "github": $github) !default;

// Theme colors
$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "secondary-alt": $secondary-alt,
    "tertiary": $tertiary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "white": $white,
    "indigo": $indigo,
    "purple": $purple,
    "yellow-100": $yellow-100,
    "yellow-200": $yellow-200,
    "gray-50": $gray-50,
    "gray-100": $gray-100,
    "gray-200": $gray-200,
    "gray-300": $gray-300,
    "gray-400": $gray-400,
    "gray-500": $gray-500,
    "gray-600": $gray-600,
    "gray-700": $gray-700,
    "gray-800": $gray-800,
    "gray-900": $gray-900
    ) !default;

    // Navbar colors
    $navbar-colors: ("primary": $primary,
) !default;

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 3 !default;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark : $dark !default;
$color-contrast-light: $white !default;

// fusv-disable
$blue-100: tint-color($blue, 8) !default;
$blue-200: tint-color($blue, 6) !default;
$blue-300: tint-color($blue, 4) !default;
$blue-400: tint-color($blue, 2) !default;
$blue-500: $blue !default;
$blue-600: shade-color($blue, 2) !default;
$blue-700: shade-color($blue, 4) !default;
$blue-800: shade-color($blue, 6) !default;
$blue-900: shade-color($blue, 8) !default;

$indigo-100: tint-color($indigo, 8) !default;
$indigo-200: tint-color($indigo, 6) !default;
$indigo-300: tint-color($indigo, 4) !default;
$indigo-400: tint-color($indigo, 2) !default;
$indigo-500: $indigo !default;
$indigo-600: shade-color($indigo, 2) !default;
$indigo-700: shade-color($indigo, 4) !default;
$indigo-800: shade-color($indigo, 6) !default;
$indigo-900: shade-color($indigo, 8) !default;

$purple-100: tint-color($purple, 8) !default;
$purple-200: tint-color($purple, 6) !default;
$purple-300: tint-color($purple, 4) !default;
$purple-400: tint-color($purple, 2) !default;
$purple-500: $purple !default;
$purple-600: shade-color($purple, 2) !default;
$purple-700: shade-color($purple, 4) !default;
$purple-800: shade-color($purple, 6) !default;
$purple-900: shade-color($purple, 8) !default;

$pink-100: tint-color($pink, 8) !default;
$pink-200: tint-color($pink, 6) !default;
$pink-300: tint-color($pink, 4) !default;
$pink-400: tint-color($pink, 2) !default;
$pink-500: $pink !default;
$pink-600: shade-color($pink, 2) !default;
$pink-700: shade-color($pink, 4) !default;
$pink-800: shade-color($pink, 6) !default;
$pink-900: shade-color($pink, 8) !default;

$red-100: tint-color($red, 8) !default;
$red-200: tint-color($red, 6) !default;
$red-300: tint-color($red, 4) !default;
$red-400: tint-color($red, 2) !default;
$red-500: $red !default;
$red-600: shade-color($red, 2) !default;
$red-700: shade-color($red, 4) !default;
$red-800: shade-color($red, 6) !default;
$red-900: shade-color($red, 8) !default;

$orange-100: tint-color($orange, 8) !default;
$orange-200: tint-color($orange, 6) !default;
$orange-300: tint-color($orange, 4) !default;
$orange-400: tint-color($orange, 2) !default;
$orange-500: $orange !default;
$orange-600: shade-color($orange, 2) !default;
$orange-700: shade-color($orange, 4) !default;
$orange-800: shade-color($orange, 6) !default;
$orange-900: shade-color($orange, 8) !default;

$yellow-100: tint-color($yellow, 8) !default;
$yellow-200: tint-color($yellow, 6) !default;
$yellow-300: tint-color($yellow, 4) !default;
$yellow-400: tint-color($yellow, 2) !default;
$yellow-500: $yellow !default;
$yellow-600: shade-color($yellow, 2) !default;
$yellow-700: shade-color($yellow, 4) !default;
$yellow-800: shade-color($yellow, 6) !default;
$yellow-900: shade-color($yellow, 8) !default;

$green-100: tint-color($green, 8) !default;
$green-200: tint-color($green, 6) !default;
$green-300: tint-color($green, 4) !default;
$green-400: tint-color($green, 2) !default;
$green-500: $green !default;
$green-600: shade-color($green, 2) !default;
$green-700: shade-color($green, 4) !default;
$green-800: shade-color($green, 6) !default;
$green-900: shade-color($green, 8) !default;

$teal-100: tint-color($teal, 8) !default;
$teal-200: tint-color($teal, 6) !default;
$teal-300: tint-color($teal, 4) !default;
$teal-400: tint-color($teal, 2) !default;
$teal-500: $teal !default;
$teal-600: shade-color($teal, 2) !default;
$teal-700: shade-color($teal, 4) !default;
$teal-800: shade-color($teal, 6) !default;
$teal-900: shade-color($teal, 8) !default;

$cyan-100: tint-color($cyan, 8) !default;
$cyan-200: tint-color($cyan, 6) !default;
$cyan-300: tint-color($cyan, 4) !default;
$cyan-400: tint-color($cyan, 2) !default;
$cyan-500: $cyan !default;
$cyan-600: shade-color($cyan, 2) !default;
$cyan-700: shade-color($cyan, 4) !default;
$cyan-800: shade-color($cyan, 6) !default;
$cyan-900: shade-color($cyan, 8) !default;
// fusv-enable

// Characters which are escaped by the escape-svg function
$escaped-characters: (("<", "%3c"),
    (">", "%3e"),
    ("#", "%23"),
    ("(", "%28"),
    (")", "%29"),
) !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret               : true !default;
$enable-rounded             : true !default;
$enable-shadows             : true !default;
$enable-gradients           : false !default;
$enable-transitions         : true !default;
$enable-reduced-motion      : true !default;
$enable-grid-classes        : true !default;
$enable-button-pointers     : true !default;
$enable-rfs                 : true !default;
$enable-validation-icons    : true !default;
$enable-negative-margins    : true !default;
$enable-deprecation-messages: true !default;
$enable-important-utilities : true !default;

// Gradient
//
// The gradient which is added to components if `$enable-gradients` is `true`
// This gradient is also added to elements with `.bg-gradient`
$gradient: linear-gradient(180deg, rgba($white, .15), rgba($white, 0)) !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: (0: 0,
    1: math.div($spacer, 4),
    2: math.div($spacer, 2),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3),
    6: ($spacer * 5),
    7: ($spacer * 8),
    8: ($spacer * 10),
    9: ($spacer * 11),
    10: ($spacer * 14),
    11: ($spacer * 16),
    12: ($spacer * 20),
    'sm': ($spacer * 1),
    'md': ($spacer * 2),
    'lg': ($spacer * 4),
    'xl': ($spacer * 8)) !default;

$negative-spacers: if($enable-negative-margins, negativify-map($spacers), null) !default;

// Body
//
// Settings for the `<body>` element.

$body-bg        : $gray-100 !default;
$body-color     : $gray-700 !default;
$body-text-align: null !default;


// Links
//
// Style anchor elements.

$link-color                             : $primary !default;
$link-decoration                        : none !default;
$link-hover-color                       : darken($link-color, 50%) !default;
$link-hover-decoration                  : none !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 10% !default;

$stretched-link-pseudo-element: after !default;
$stretched-link-z-index       : 1 !default;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 1rem !default;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// scss-docs-start grid-breakpoints
$grid-breakpoints: (xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px) !default;
// scss-docs-end grid-breakpoints

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px) !default;
// scss-docs-end container-max-widths

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns     : 12 !default;
$grid-gutter-width: 1.5rem !default;
$grid-row-columns : 6 !default;

$gutters: $spacers !default;

// Container padding

$container-padding-x: 1rem !default;


// Components
//
// Define common padding and border radius sizes and more.

$border-width      : .0625rem !default;
$border-width-md   : 0.125rem !default;
$border-width-lg   : 0.25rem !default;
$border-width-xl   : 0.375rem !default;
$border-color-white: $white !default;
$border-color      : $gray-200 !default;


$border-radius     : .5rem !default;
$border-radius-sm  : .35rem !default;
$border-radius-lg  : 1rem !default;
$border-radius-xl  : 3rem !default;
$border-radius-pill: 50rem !default;
$circle-radius     : 50% !default;

$component-active-color       : $white !default;
$component-active-bg          : $primary !default;
$component-active-border-color: $primary !default;

$component-hover-color       : $gray-300 !default;
$component-hover-bg          : $gray-300 !default;
$component-hover-border-color: $gray-300 !default;

$rounded-pill: 50rem !default;

$box-shadow      : 0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06) !default;
$box-shadow-sm   : 0 2px 5px rgba(140, 152, 164, .2) !default;
$box-shadow-lg   : 0 1rem 3rem rgba($black, .175) !default;
$box-shadow-inset: inset 0 1px 2px rgba($black, .0125) !default;

$component-active-color: $white !default;
$component-active-bg   : $primary !default;

$caret-width         : .3em !default;
$caret-vertical-align: $caret-width * .85 !default;
$caret-spacing       : $caret-width * .85 !default;

$transition-base     : all .2s ease !default;
$transition-transform: transform .2s ease !default;
$transition-fade     : opacity .15s linear !default;
$transition-collapse : height .35s ease !default;
$transition-tabs     : all 0.2s !default;

// scss-docs-start embed-responsive-aspect-ratios
$embed-responsive-aspect-ratios: ("21by9": (x: 21,
        y: 9),
    "16by9": (x: 16,
        y: 9),
    "4by3": (x: 4,
        y: 3),
    "1by1": (x: 1,
        y: 1)) !default;
// scss-docs-end embed-responsive-aspect-ratios

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// font awesome
$fontawesome-webfonts-path: '../vendor/font-awesome/webfonts' !default;
$font-awesome-5           : 'Font Awesome 5 Free' !default;

// stylelint-disable value-keyword-case
$font-family-sans-serif: 'Inter', sans-serif !default;
// stylelint-enable value-keyword-case
$font-family-base: var(--bs-font-sans-serif) !default;
$font-family-code: var(--bs-font-monospace) !default;

// $font-size-root effects the value of `rem`, which is used for as well font sizes, paddings and margins
// $font-size-base effects the font size of the body text
$font-size-root: null !default;
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-xs  : ($font-size-base * .75) !default;
$font-size-sm  : ($font-size-base * .875) !default;
$font-size-md  : ($font-size-base * 1) !default;
$font-size-lg  : ($font-size-base * 1.25) !default;
$font-size-xl  : ($font-size-base * 1.5) !default;
$font-size-xxl : ($font-size-base * 2) !default;

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            500 !default;
$font-weight-bolder:          600 !default;
$font-weight-extrabold:       700 !default;
$font-weight-black:           800 !default;

$font-weight-base: $font-weight-normal !default;

$line-height-base: 1.5 !default;
$line-height-sm  : 1.25 !default;
$line-height-lg  : 2 !default;

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

$headings-margin-bottom: math.div($spacer, 2) !default;
$headings-font-family  : null !default;
$headings-font-style   : null !default;
$headings-font-weight  : $font-weight-bold !default;
$headings-line-height  : 1.3 !default;
$headings-color        : $gray-900 !default;

// Display headings
$display1-size: 5rem !default;
$display2-size: 3.5rem !default;
$display3-size: 2.5rem !default;
$display4-size: 1.875rem !default;

// scss-docs-start display-headings
$display-font-sizes: (1: 5rem,
    2: 3.5rem,
    3: 2.5rem,
    4: 1.875rem,
    5: 1.2rem,
    6: 1rem) !default;

$display-font-weight: $font-weight-bold !default;
$display-line-height: $headings-line-height !default;
// scss-docs-end display-headings

$paragraph-font-size  : 1rem !default;
$paragraph-font-weight: 300 !default;
$paragraph-line-height: 1.6 !default;

$lead-font-size  : $font-size-base * 1.25 !default;
$lead-font-weight: 300 !default;

$small-font-size: .875em !default;

$sub-sup-font-size: .75em !default;

$text-muted: #58677d !default;

$initialism-font-size: $small-font-size !default;

$blockquote-margin-y        : $spacer !default;
$blockquote-font-size       : $font-size-base * 1.25 !default;
$blockquote-footer-color    : $gray-600 !default;
$blockquote-footer-font-size: $small-font-size !default;

$hr-margin-y: $spacer !default;
$hr-color   : inherit !default;
$hr-height  : $border-width !default;
$hr-opacity : .25 !default;

$legend-margin-bottom: .5rem !default;
$legend-font-size    : 1.5rem !default;
$legend-font-weight  : null !default;

$mark-padding: .2em !default;

$dt-font-weight: $font-weight-bold !default;

$nested-kbd-font-weight: $font-weight-bold !default;

$list-inline-padding: .5rem !default;

$mark-bg: #fcf8e3 !default;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

// scss-docs-start table-variables
$table-cell-padding-y   : .75rem !default;
$table-cell-padding-x   : .5rem !default;
$table-cell-padding-y-sm: .25rem !default;
$table-cell-padding-x-sm: .25rem !default;

$table-cell-vertical-align: top !default;

$table-color: $body-color !default;
$table-bg   : transparent !default;

$table-striped-color    : $table-color !default;
$table-striped-bg-factor: .05 !default;
$table-striped-bg       : rgba($black, $table-striped-bg-factor) !default;

$table-active-color    : $table-color !default;
$table-active-bg-factor: .1 !default;
$table-active-bg       : rgba($black, $table-active-bg-factor) !default;

$table-hover-color    : $table-color !default;
$table-hover-bg-factor: .075 !default;
$table-hover-bg       : rgba($black, $table-hover-bg-factor) !default;

$table-border-factor: .1 !default;
$table-border-width : $border-width !default;
$table-border-color : $border-color !default;

$table-striped-order: odd !default;

$table-group-seperator-color: $light !default;

$table-caption-color: $text-muted !default;

$table-bg-scale: -80% !default;

$table-head-spacer-y      : .75rem !default;
$table-head-spacer-x      : 1rem !default;
$table-head-font-size     : .75rem !default;
$table-head-text-transform: uppercase !default;
$table-body-font-size     : $font-size-sm !default;

$table-variants: ("primary": shift-color($primary, $table-bg-scale),
    "secondary": shift-color($secondary, $table-bg-scale),
    "success": shift-color($success, $table-bg-scale),
    "info": shift-color($info, $table-bg-scale),
    "warning": shift-color($warning, $table-bg-scale),
    "danger": shift-color($danger, $table-bg-scale),
    "light": $light,
    "dark": $dark,
) !default;

// Accordion
$accordion-padding-y    : 1rem !default;
$accordion-padding-x    : 1.25rem !default;
$accordion-color        : $body-color !default;
$accordion-bg           : transparent !default;
$accordion-border-width : $border-width !default;
$accordion-border-color : $gray-200 !default;
$accordion-border-radius: $border-radius !default;

$accordion-body-padding-y: $accordion-padding-y !default;
$accordion-body-padding-x: $accordion-padding-x !default;

$accordion-button-padding-y   : $accordion-padding-y !default;
$accordion-button-padding-x   : $accordion-padding-x !default;
$accordion-button-color       : $accordion-color !default;
$accordion-button-bg          : $accordion-bg !default;
$accordion-button-active-bg   : $gray-100 !default;
$accordion-button-active-color: $gray-600 !default;


$accordion-icon-width       : 1.25rem !default !default;
$accordion-icon-color       : $accordion-color !default !default;
$accordion-icon-active-color: $accordion-button-active-color !default !default;
$accordion-icon-transition  : transform .2s ease-in-out !default !default;
$accordion-icon-transform   : rotate(180deg) !default !default;

$accordion-button-icon       : url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default !default;
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default !default;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y  : .5rem !default;
$input-btn-padding-x  :  1rem !default;
$input-btn-font-family: null !default;
$input-btn-font-size  : $font-size-sm !default;
$input-btn-line-height: $line-height-base !default;

$input-btn-focus-width        : .18rem !default;
$input-btn-focus-color-opacity: .25 !default;
$input-btn-focus-color        : rgba($component-active-bg, $input-btn-focus-color-opacity) !default;
$input-btn-focus-box-shadow   : 0 0 0 $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y-sm: .375rem !default;
$input-btn-padding-x-sm: .625rem !default;
$input-btn-font-size-sm: $font-size-sm !default;

$input-btn-padding-y-lg: .5rem !default;
$input-btn-padding-x-lg: 1rem !default;
$input-btn-font-size-lg: $font-size-lg !default;

$input-btn-border-width: $border-width !default;

$shadow-input      : $box-shadow !default;
$shadow-input-focus: .1rem .1rem 0 rgba($gray-200, .5) !default;


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y  : $input-btn-padding-y !default;
$btn-padding-x  : $input-btn-padding-x !default;
$btn-font-family: $input-btn-font-family !default;
$btn-font-size  : $input-btn-font-size !default;
$btn-line-height: $input-btn-line-height !default;
$btn-white-space: null !default; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm: $input-btn-padding-y-sm !default;
$btn-padding-x-sm: $input-btn-padding-x-sm !default;
$btn-font-size-sm: $input-btn-font-size-sm !default;

$btn-padding-y-lg: $input-btn-padding-y-lg !default;
$btn-padding-x-lg: $input-btn-padding-x-lg !default;
$btn-font-size-lg: $input-btn-font-size-lg !default;

$btn-border-width: $input-btn-border-width !default;

$btn-font-weight: $font-weight-bold !default;
$btn-box-shadow : inset 0 1px 0 rgba($white, .15),
0 1px 1px rgba($black, .075) !default;
$btn-focus-width      : $input-btn-focus-width !default;
$btn-focus-box-shadow : $input-btn-focus-box-shadow !default;
$btn-disabled-opacity : .65 !default;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, .125) !default;

$btn-link-color         : $link-color !default;
$btn-link-hover-color   : $link-hover-color !default;
$btn-link-disabled-color: $gray-600 !default;

$btn-block-spacing-y: .5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius   : $border-radius !default;
$btn-border-radius-sm: $border-radius !default;
$btn-border-radius-lg: $border-radius !default;

$btn-transition: color .15s ease-in-out,
background-color .15s ease-in-out,
border-color .15s ease-in-out,
box-shadow .15s ease-in-out !default;

$btn-close-width:            1em !default;
$btn-close-height:           $btn-close-width !default;
$btn-close-padding-x:        .25em !default;
$btn-close-padding-y:        $btn-close-padding-x !default;
$btn-close-color:            $black !default;
$btn-close-bg:               url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>") !default;
$btn-close-color-white:      $white !default;
$btn-close-bg-white:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color-white}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>") !default;
$btn-close-focus-shadow:     $input-btn-focus-box-shadow !default;
$btn-close-opacity:          .5 !default;
$btn-close-hover-opacity:    .75 !default;
$btn-close-focus-opacity:    1 !default;
$btn-close-disabled-opacity: .25 !default;
$btn-close-white-filter:     invert(1) grayscale(100%) brightness(200%) !default;

// Forms

$form-text-margin-top : .25rem !default;
$form-text-font-size  : $small-font-size !default;
$form-text-font-style : null !default;
$form-text-font-weight: null !default;
$form-text-color      : $text-muted !default;

$form-label-margin-bottom: .5rem !default;
$form-label-font-size    : null !default;
$form-label-font-style   : null !default;
$form-label-font-weight  : null !default;
$form-label-color        : null !default;

$input-padding-y  : $input-btn-padding-y !default;
$input-padding-x  : $input-btn-padding-x !default;
$inpu-lg-padding-y: 1rem !default;
$input-font-family: $input-btn-font-family !default;
$input-font-size  : $input-btn-font-size !default;
$input-font-weight: $font-weight-base !default;
$input-line-height: $input-btn-line-height !default;

$input-padding-y-sm: $input-btn-padding-y-sm !default;
$input-padding-x-sm: $input-btn-padding-x-sm !default;
$input-font-size-sm: $input-btn-font-size-sm !default;

$input-padding-y-lg: $input-btn-padding-y-lg !default;
$input-padding-x-lg: $input-btn-padding-x-lg !default;
$input-font-size-lg: $input-btn-font-size-lg !default;

$input-bg                   : $white !default;
$input-disabled-bg          : $gray-200 !default;
$input-disabled-border-color: null !default;

$input-color       : $gray-500 !default;
$input-border-color: $gray-300 !default;
$input-border-width: $input-btn-border-width !default;
$input-box-shadow  : 0 1px 2px 0 rgba(0, 0, 0, 0.07) !default;

$input-border-radius   : $border-radius !default;
$input-border-radius-sm: $border-radius !default;
$input-border-radius-lg: $border-radius !default;

$input-focus-bg          : $input-bg !default;
$input-focus-border-color: lighten($component-active-bg, 25%) !default;
$input-focus-color       : $input-color !default;
$input-focus-width       : $input-btn-focus-width !default;
$input-focus-box-shadow  : $input-btn-focus-box-shadow !default;

$input-placeholder-color: $gray-600 !default;
$input-plaintext-color  : $body-color !default;

$input-height-border: $input-border-width * 2 !default;

$input-height-inner        : add($input-line-height * 1em, $input-padding-y * 2) !default;
$input-height-inner-half   : add($input-line-height * .5em, $input-padding-y) !default;
$input-height-inner-quarter: add($input-line-height * .25em, math.div($input-padding-y, 2)) !default;

$input-height   : add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false)) !default;
$input-height-sm: add($input-line-height * 1em, add($input-padding-y-sm * 2, $input-height-border, false)) !default;
$input-height-lg: add($input-line-height * 1em, add($input-padding-y-lg * 2, $input-height-border, false)) !default;
$input-height-xl: calc(#{$input-line-height * 1.5em} + #{$input-btn-padding-y-lg * 2} + #{$input-height-border}) !default;

$input-transition: border-color .15s ease-in-out,
box-shadow .15s ease-in-out !default;

// Custom forms

$custom-control-gutter        : 1.75rem !default;
$custom-control-spacer-x      : 1rem !default;
$custom-control-line-height   : 1 !default;
$custom-control-indicator-size: 1.25rem !default;

$custom-control-bg          : $gray-400 !default;
$custom-control-indicator-bg: $white !default;
$custom-control-border-width: $border-width !default;
$custom-control-border-color: $input-border-color !default;
$custom-control-box-shadow  : none !default;



$custom-control-indicator-hover-color       : $component-hover-color !default;
$custom-control-indicator-hover-bg          : $primary !default;
$custom-control-indicator-hover-border-color: $component-hover-border-color !default;

$custom-control-indicator-active-color       : $component-active-color !default;
$custom-control-indicator-active-border-color: $component-active-border-color !default;


$custom-control-indicator-checked-color       : $component-active-color !default;
$custom-control-indicator-checked-bg          : $primary !default;
$custom-control-indicator-checked-border-color: $primary !default;
$custom-control-indicator-checked-disabled-bg : rgba($primary, .7) !default;
$custom-control-indicator-border-width        : 1px !default;

$custom-control-indicator-disabled-bg: $gray-200 !default;
$custom-control-label-disabled-color : $gray-800 !default;

$custom-checkbox-bg                     : $gray-400 !default;
$custom-checkbox-checked-bg             : $primary !default;
$custom-checkbox-disabled-checked-bg    : rgba($primary, .7) !default;
$custom-checkbox-indicator-border-radius: $border-radius-xl !default;
$custom-checkbox-indicator-border-width : $border-width-md !default;
$custom-checkbox-indicator-icon-checked : $font-awesome-5 !default;
$square-checkbox-indicator-border-radius: 3px !default;

$custom-toggle-slider-bg          : $gray-400 !default;
$custom-toggle-slider-indicator-bg: $white !default;
$custom-toggle-checked-bg         : $primary !default;
$custom-toggle-disabled-bg        : $gray-200 !default;
$custom-toggle-disabled-checked-bg: rgba($primary, .7) !default;
$custom-toggle-border-radius      : .8rem !default;

$custom-switch-indicator-size: calc(#{$custom-control-indicator-size} - #{$custom-control-indicator-border-width * 4}) !default;

$custom-checkbox-disabled-checked-color: $gray-800 !default;


$form-check-input-width  : 1.125em !default;
$form-check-min-height   : $font-size-base * $line-height-base !default;
$form-check-padding-left : $form-check-input-width+.5em !default;
$form-check-margin-bottom: .125rem !default;
$form-check-label-color  : null !default;
$form-check-label-cursor : null !default;
$form-check-transition   : background-color .2s ease-in-out,
background-position .2s ease-in-out,
border-color .2s ease-in-out,
box-shadow .2s ease-in-out !default;

$form-check-input-active-filter: brightness(90%) !default;

$form-check-input-bg              : $body-bg !default;
$form-check-input-border          : 1px solid $input-border-color !default;
$form-check-input-border-radius   : .25em !default;
$form-check-radio-border-radius   : 50% !default;
$form-check-input-focus-border    : $input-focus-border-color !default;
$form-check-input-focus-box-shadow: $input-btn-focus-box-shadow !default;

$form-check-input-checked-color       : $component-active-color !default;
$form-check-input-checked-bg-color    : $component-active-bg !default;
$form-check-input-checked-border-color: $form-check-input-checked-bg-color !default;
$form-check-input-checked-bg-image    : url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>") !default;
$form-check-radio-checked-bg-image    : url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>") !default;

$form-check-input-indeterminate-color       : $component-active-color !default;
$form-check-input-indeterminate-bg-color    : $component-active-bg !default;
$form-check-input-indeterminate-border-color: $form-check-input-indeterminate-bg-color !default;
$form-check-input-indeterminate-bg-image    : url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>") !default;

$form-switch-color        : $gray-600 !default;
$form-switch-width        : 2.25em !default;
$form-switch-padding-left : $form-switch-width+.5em !default;
$form-switch-bg-image     : url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>") !default;
$form-switch-border-radius: $form-switch-width !default;

$form-switch-focus-color   : $input-focus-border-color !default;
$form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>") !default;

$form-switch-checked-color      : $component-active-color !default;
$form-switch-checked-bg-image   : url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>") !default;
$form-switch-checked-bg-position: right center !default;

$form-check-inline-margin-right: 1rem !default;

// Input groups

// scss-docs-start input-group-variables
$input-group-addon-padding-y:           .5rem !default;
$input-group-addon-padding-x:           .625rem !default;
$input-group-addon-font-weight:         $input-font-weight !default;
$input-group-addon-color:               $gray-400 !default;
$input-group-addon-bg:                  $white !default;
$input-group-addon-border-color:        $input-border-color !default;
// scss-docs-end input-group-variables


$form-select-padding-y            : $input-padding-y !default;
$form-select-padding-x            : $input-padding-x !default;
$form-select-font-family          : $input-font-family !default;
$form-select-font-size            : $input-font-size !default;
$form-select-height               : $input-height !default;
$form-select-indicator-padding    : 1rem !default; // Extra padding to account for the presence of the background-image based indicator
$form-select-font-weight          : $input-font-weight !default;
$form-select-line-height          : $input-line-height !default;
$form-select-color                : $input-color !default;
$form-select-disabled-color       : $gray-600 !default;
$form-select-bg                   : $input-bg !default;
$form-select-disabled-bg          : $gray-200 !default;
$form-select-disabled-border-color: $input-disabled-border-color !default;
$form-select-bg-position          : right $form-select-padding-x center !default;
$form-select-bg-size              : 16px 12px !default; // In pixels because image dimensions
$form-select-indicator-color      : $gray-800 !default;
$form-select-indicator            : url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>") !default;

$form-select-feedback-icon-padding-right: add(1em * .75, (2 * $form-select-padding-y * .75) + $form-select-padding-x + $form-select-indicator-padding) !default;
$form-select-feedback-icon-position     : center right ($form-select-padding-x + $form-select-indicator-padding) !default;
$form-select-feedback-icon-size         : $input-height-inner-half $input-height-inner-half !default;

$form-select-border-width : $input-border-width !default;
$form-select-border-color : $input-border-color !default;
$form-select-border-radius: $border-radius !default;
$form-select-box-shadow   : $box-shadow-inset !default;

$form-select-focus-border-color: $input-focus-border-color !default;
$form-select-focus-width       : $input-focus-width !default;
$form-select-focus-box-shadow  : 0 0 0 $form-select-focus-width $input-btn-focus-color !default;

$form-select-padding-y-sm: $input-padding-y-sm !default;
$form-select-padding-x-sm: $input-padding-x-sm !default;
$form-select-font-size-sm: $input-font-size-sm !default;
$form-select-height-sm   : $input-height-sm !default;

$form-select-padding-y-lg: $input-padding-y-lg !default;
$form-select-padding-x-lg: $input-padding-x-lg !default;
$form-select-font-size-lg: $input-font-size-lg !default;
$form-select-height-lg   : $input-height-lg !default;

$form-range-track-width        : 100% !default;
$form-range-track-height       : .5rem !default;
$form-range-track-cursor       : pointer !default;
$form-range-track-bg           : $gray-300 !default;
$form-range-track-border-radius: 1rem !default;
$form-range-track-box-shadow   : $box-shadow-inset !default;

$form-range-thumb-width        : 1rem !default;
$form-range-thumb-height       : $form-range-thumb-width !default;
$form-range-thumb-bg           : $component-active-bg !default;
$form-range-thumb-border       : 0 !default;
$form-range-thumb-border-radius: 1rem !default;
$form-range-thumb-box-shadow   : 0 .1rem .25rem rgba($black, .1) !default;
$form-range-thumb-focus-box-shadow: 0 0 0 1px $body-bg,
$input-focus-box-shadow !default;
$form-range-thumb-focus-box-shadow-width: $input-focus-width !default; // For focus box shadow issue in Edge
$form-range-thumb-active-bg             : lighten($component-active-bg, 35%) !default;
$form-range-thumb-disabled-bg           : $gray-500 !default;
$form-range-thumb-transition            : background-color .15s ease-in-out,
border-color .15s ease-in-out,
box-shadow .15s ease-in-out !default;

$form-file-height               : $input-height !default;
$form-file-focus-border-color   : $input-focus-border-color !default;
$form-file-focus-box-shadow     : $input-focus-box-shadow !default;
$form-file-disabled-bg          : $input-disabled-bg !default;
$form-file-disabled-border-color: $input-disabled-border-color !default;

$form-file-padding-y    : $input-padding-y !default;
$form-file-padding-x    : $input-padding-x !default;
$form-file-line-height  : $input-line-height !default;
$form-file-font-family  : $input-font-family !default;
$form-file-font-weight  : $input-font-weight !default;
$form-file-color        : $input-color !default;
$form-file-bg           : $input-bg !default;
$form-file-border-width : $input-border-width !default;
$form-file-border-color : $input-border-color !default;
$form-file-border-radius: $input-border-radius !default;
$form-file-box-shadow   : $input-box-shadow !default;
$form-file-button-color : $form-file-color !default;
$form-file-button-bg    : $input-group-addon-bg !default;

$form-file-padding-y-sm: $input-padding-y-sm !default;
$form-file-padding-x-sm: $input-padding-x-sm !default;
$form-file-font-size-sm: $input-font-size-sm !default;
$form-file-height-sm   : $input-height-sm !default;

$form-file-padding-y-lg: $input-padding-y-lg !default;
$form-file-padding-x-lg: $input-padding-x-lg !default;
$form-file-font-size-lg: $input-font-size-lg !default;
$form-file-height-lg   : $input-height-lg !default;

$input-muted-bg               : $gray-100 !default;
$input-placeholder-color      : $gray-800 !default;
$input-focus-placeholder-color: $gray-800 !default;

// Form validation

$form-feedback-margin-top   : $form-text-margin-top !default;
$form-feedback-font-size    : $form-text-font-size !default;
$form-feedback-font-style   : $form-text-font-style !default;
$form-feedback-valid-color  : $success !default;
$form-feedback-invalid-color: $danger !default;

$form-feedback-icon-valid-color  : $form-feedback-valid-color !default;
$form-feedback-icon-valid        : url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>") !default;
$form-feedback-icon-invalid-color: $form-feedback-invalid-color !default;
$form-feedback-icon-invalid      : url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}' viewBox='0 0 12 12'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>") !default;

// scss-docs-start form-validation-states
$form-validation-states: ("valid": ("color": $form-feedback-valid-color,
        "icon": $form-feedback-icon-valid),
    "invalid": ("color": $form-feedback-invalid-color,
        "icon": $form-feedback-icon-invalid)) !default;
// scss-docs-end form-validation-states

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

// scss-docs-start zindex-stack
$zindex-dropdown      : 1000 !default;
$zindex-sticky        : 1020 !default;
$zindex-fixed         : 1030 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal         : 1050 !default;
$zindex-popover       : 1060 !default;
$zindex-tooltip       : 1070 !default;
// scss-docs-end zindex-stack

// No UI Slider

$noui-target-bg                 : $gray-400 !default;
$noui-target-thickness          : 5px !default;
$noui-target-border-radius      : 5px !default;
$noui-target-border-color       : 0 !default;
$noui-target-box-shadow         : inset $box-shadow-sm !default;
$noui-box-shadow                : $box-shadow-sm !default;
$noui-slider-connect-bg         : #f5f8fb !default;
$noui-slider-connect-disabled-bg: $gray-200 !default;
$noui-handle-active-shadow      : 0 0 0 0.1rem #d1d7e0 !default;
$noui-handle-width              : 15px !default;
$noui-handle-bg                 : $primary !default;
$noui-handle-border             : 0 !default;
$noui-handle-border-radius      : $border-radius !default;
$noui-origin-border-radius      : $circle-radius !default;


// Navs

$nav-link-padding-y   : .5rem !default;
$nav-link-padding-x   : 1rem !default;
$nav-link-color       : $gray-800 !default;
$nav-link-hover-color : $dark !default;
$nav-link-active-color: $gray-800 !default;
$nav-link-shadow      : none !default;
$nav-link-transition  : color .15s ease-in-out,
background-color .15s ease-in-out,
border-color .15s ease-in-out !default;
$nav-link-disabled-color: $gray-600 !default;

$nav-tabs-border-color            : $light !default;
$nav-tabs-border-width            : $border-width !default;
$nav-tabs-border-radius           : $border-radius !default;
$nav-tabs-link-hover-border-color : $gray-200 $gray-200 $nav-tabs-border-color !default;
$nav-tabs-link-active-color       : $gray-700 !default;
$nav-tabs-link-active-bg          : $light !default;
$nav-tabs-link-active-border-color: $light !default;

$nav-pills-padding-y: .75rem !default;
$nav-pills-padding-x: .85rem !default;

$nav-pills-space-x      : 1rem !default;
$nav-pills-border-circle: 50% !default;

$nav-pills-bg           : $white !default;
$nav-pills-border-width : 1px !default;
$nav-pills-border-color : $light !default;
$nav-pills-border-radius: $border-radius !default;

$nav-pills-link-hover-color   : $primary !default;
$nav-pills-link-active-color  : $primary !default;
$nav-pills-active-border-color: $gray-300 !default;
$nav-pills-link-active-bg     : #ffffff !default;

// Owl navs

$owl-nav-color           : $gray-600 !default;
$owl-nav-color-hover     : $gray-900 !default;
$owl-nav-font-size       : $font-size-xxl !default;
$owl-nav-rounded         : 3px !default;
$owl-nav-margin          : 5px !default;
$owl-nav-padding         : 4px 7px !default;
$owl-nav-background      : $gray-400 !default;
$owl-nav-background-hover: $gray-800 !default;
$owl-nav-disabled-opacity: 0.5 !default;

// Owl dots

$owl-dot-width            : 10px !default;
$owl-dot-height           : 10px !default;
$owl-dot-rounded          : $circle-radius !default;
$owl-dot-margin           : 5px 5px !default;
$owl-dot-border-width     : 2px !default;
$owl-dot-background       : $gray-400 !default;
$owl-dot-background-active: $gray-800 !default;


// Navbar

$navbar-padding-y: 1rem !default;
$navbar-padding-x: null !default;

$navbar-nav-link-padding-x: .8rem !default;

$navbar-brand-font-size   : $font-size-lg !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height          : $font-size-base * $line-height-base+$nav-link-padding-y * 4 !default;
$navbar-brand-height      : $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-padding-y   : 0 !default;
$navbar-brand-margin-right: 1rem !default;

$navbar-toggler-padding-y    : .4rem !default;
$navbar-toggler-padding-x    : .6rem !default;
$navbar-toggler-font-size    : $font-size-lg !default;
$navbar-toggler-border-radius: $btn-border-radius !default;
$navbar-toggler-focus-width  : $btn-focus-width !default;
$navbar-toggler-transition   : box-shadow .15s ease-in-out !default;

$navbar-dark-color               : rgba($white, 0.9) !default;
$navbar-dark-hover-color         : rgba($white, 1) !default;
$navbar-dark-active-color        : $white !default;
$navbar-dark-disabled-color      : rgba($white, .9) !default;
$navbar-dark-toggler-icon-bg     : url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-dark-toggler-border-color: rgba($white, .1) !default;

$navbar-light-color               : rgba($black, .9) !default;
$navbar-light-hover-color         : rgba($black, 1) !default;
$navbar-light-active-color        : rgba($black, 1) !default;
$navbar-light-disabled-color      : rgba($black, .9) !default;
$navbar-light-toggler-icon-bg     : url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-light-toggler-border-color: rgba($black, .1) !default;

$navbar-light-brand-color      : $navbar-light-active-color !default;
$navbar-light-brand-hover-color: $navbar-light-active-color !default;
$navbar-dark-brand-color       : $navbar-dark-active-color !default;
$navbar-dark-brand-hover-color : $navbar-dark-active-color !default;


// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width       : 12rem !default;
$dropdown-padding-y       : .5rem !default;
$dropdown-spacer          : .125rem !default;
$dropdown-font-size       : .875rem !default;
$dropdown-item-font-weight: $font-weight-normal !default;
$dropdown-item-hover-color: $gray-900 !default;

$dropdown-color              : $body-color !default;
$dropdown-bg                 : $white !default;
$dropdown-border-color       : $border-color !default;
$dropdown-border-radius      : $border-radius-sm !default;
$dropdown-border-width       : $border-width !default;
$dropdown-inner-border-radius: subtract($dropdown-border-radius, $dropdown-border-width) !default;
$dropdown-divider-bg         : $gray-200 !default;
$dropdown-divider-margin-y   : math.div($spacer, 2) !default;
$dropdown-box-shadow         : 0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05) !default;

$dropdown-link-color      : $gray-900 !default;
$dropdown-link-hover-color: darken($gray-900, 5%) !default;
$dropdown-link-hover-bg   : $gray-100 !default;

$dropdown-link-active-color: $gray-900 !default;
$dropdown-link-active-bg   : $gray-200 !default;

$dropdown-link-disabled-color: $gray-600 !default;

$dropdown-item-padding-y: math.div($spacer, 4) !default;
$dropdown-item-padding-x: $spacer !default;

$dropdown-header-color      : $gray-900 !default;
$dropdown-header-font-weight: $font-weight-bold !default;
$dropdown-header-padding    : $dropdown-padding-y $dropdown-item-padding-x !default;


// Pagination

$pagination-padding-y   : .375rem !default;
$pagination-padding-x   : .75rem !default;
$pagination-padding-y-sm: .25rem !default;
$pagination-padding-x-sm: .5rem !default;
$pagination-padding-y-lg: .75rem !default;
$pagination-padding-x-lg: 1.5rem !default;

$pagination-color        : $link-color !default;
$pagination-bg           : $white !default;
$pagination-border-width : $border-width !default;
$pagination-border-radius: $border-radius !default;
$pagination-margin-left  : -$pagination-border-width !default;
$pagination-border-color : $light !default;

$pagination-focus-box-shadow: $input-btn-focus-box-shadow !default;
$pagination-focus-outline   : 0 !default;

$pagination-hover-color       : $link-hover-color !default;
$pagination-hover-bg          : $gray-200 !default;
$pagination-hover-border-color: $gray-300 !default;

$pagination-active-color       : $component-active-color !default;
$pagination-active-bg          : $component-active-bg !default;
$pagination-active-border-color: $pagination-active-bg !default;

$pagination-disabled-color       : $gray-600 !default;
$pagination-disabled-bg          : $white !default;
$pagination-disabled-border-color: $gray-300 !default;


// Cards

$card-spacer-y           : 1.25rem !default;
$card-spacer-x           : 1.5rem !default;
$card-social-padding     : .25rem .375rem !default;
$card-title-spacer-y     : .875rem !default;
$card-border-width       : $border-width !default;
$card-border-radius      : $border-radius !default;
$card-border-color       : rgba($black, .125) !default;
$card-inner-border-radius: subtract($card-border-radius, $card-border-width) !default;
$card-cap-padding-y      : 1.25rem !default;
$card-cap-padding-x      : $card-spacer-x !default;
$card-cap-bg             : rgba($black, .03) !default;
$card-cap-color          : null !default;
$card-height             : null !default;
$card-color              : null !default;
$card-bg                 : $white !default;

$card-img-overlay-padding: $spacer !default;

$card-group-margin     : math.div($grid-gutter-width, 2) !default;
$transition-bezier-card: cubic-bezier(0.34, 1.45, 0.7, 1) !default;

//Timelines
$timeline-font-size        : $font-size-xxl !default;
$timeline-font-color       : $body-color !default;
$timeline-border-color     : $light !default;
$timeline-border-color-dark: $gray-500 !default;
$timeline-background-color : $white !default;
$timeline-line-height      : 3 !default;
$timeline-border-width     : $border-width !default;
$timeline-circle-radius    : 50% !default;
$timeline-spacer-y         : 1.25rem !default;
$timeline-spacer-x         : 2.75rem !default;


// Icons & Shapes sizes

$icon-size-xxs:         1rem !default; 
$icon-size-xs:          1.25rem !default; 
$icon-size-sm:          1.5rem !default; 
$icon-size:             2rem !default;  
$icon-size-lg:          3.5rem !default;
$icon-size-xl:          4.5rem !default;
$icon-size-xxl:         6rem !default;

$icon-shape-xxs:        1.5rem !default;
$icon-shape-xs:         2.5rem !default;
$icon-shape-sm:         3.275rem !default;
$icon-shape:            4rem !default;
$icon-shape-lg:         5rem !default;
$icon-shape-xl:         6rem !default;
$icon-shape-xxl:        7.5rem !default;


// Steps
$step-number-size : 12rem !default;
$step-shape-size  : 7rem !default;
$step-border-width: $border-width-md !default;
$step-icon-size   : $icon-size-xl !default;

// Tooltips

$tooltip-font-size    : $font-size-sm !default;
$tooltip-max-width    : 200px !default;
$tooltip-color        : $white !default;
$tooltip-bg           : $dark !default;
$tooltip-border-radius: $border-radius !default;
$tooltip-opacity      : .99 !default;
$tooltip-padding-y    : .375rem !default;
$tooltip-padding-x    : .55rem !default;
$tooltip-margin       : 2rem !default;

$tooltip-arrow-width : .8rem !default;
$tooltip-arrow-height: .4rem !default;
$tooltip-arrow-color : $tooltip-bg !default;

// Form tooltips must come after regular tooltips
$form-feedback-tooltip-padding-y    : $tooltip-padding-y !default;
$form-feedback-tooltip-padding-x    : $tooltip-padding-x !default;
$form-feedback-tooltip-font-size    : $tooltip-font-size !default;
$form-feedback-tooltip-line-height  : null !default;
$form-feedback-tooltip-opacity      : $tooltip-opacity !default;
$form-feedback-tooltip-border-radius: $tooltip-border-radius !default;


// Popovers

$popover-font-size          : $font-size-sm !default;
$popover-bg                 : $gray-800 !default;
$popover-max-width          : 276px !default;
$popover-border-width       : $border-width !default;
$popover-border-color       : rgba($black, .2) !default;
$popover-border-radius      : $border-radius-lg !default;
$popover-inner-border-radius: subtract($popover-border-radius, $popover-border-width) !default;
$popover-box-shadow         : $box-shadow !default;

$popover-header-bg       : darken($popover-bg, 3%) !default;
$popover-header-color    : $white !default;
$popover-header-padding-y: .5rem !default;
$popover-header-padding-x: $spacer !default;

$popover-body-color    : $white !default;
$popover-body-padding-y: $spacer !default;
$popover-body-padding-x: $spacer !default;

$popover-arrow-width : 1rem !default;
$popover-arrow-height: .5rem !default;
$popover-arrow-color : $popover-bg !default;

$popover-arrow-outer-color: fade-in($popover-border-color, .05) !default;


// Toasts

$toast-max-width       : 350px !default;
$toast-padding-x       : .75rem !default;
$toast-padding-y       : .25rem !default;
$toast-font-size       : .875rem !default;
$toast-color           : null !default;
$toast-background-color: rgba($white, .85) !default;
$toast-border-width    : 1px !default;
$toast-border-color    : rgba(0, 0, 0, .1) !default;
$toast-border-radius   : $border-radius !default;
$toast-box-shadow      : $box-shadow !default;

$toast-header-color           : $gray-600 !default;
$toast-header-background-color: rgba($white, .85) !default;
$toast-header-border-color    : rgba(0, 0, 0, .05) !default;


// Badges

$badge-font-size    : .75em !default;
$badge-font-weight  : $font-weight-bolder !default;
$badge-padding-y    : .2rem !default;
$badge-padding-x    : .4rem !default;
$badge-color        : $white !default;
$badge-border-radius: $border-radius !default;

$badge-pill-padding-x    : .875em !default;
$badge-pill-border-radius: 10rem !default;

$badge-circle-size: 2rem !default;


// Modals

// Padding applied to the modal body
$modal-inner-padding: $spacer !default;

// Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
$modal-footer-margin-between: .5rem !default;

$modal-dialog-margin        : .5rem !default;
$modal-dialog-margin-y-sm-up: 1.75rem !default;

$modal-title-line-height: $line-height-base !default;

$modal-content-color              : null !default;
$modal-content-bg                 : $white !default;
$modal-content-border-color       : rgba($black, .2) !default;
$modal-content-border-width       : $border-width !default;
$modal-content-border-radius      : $border-radius !default;
$modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width) !default;
$modal-content-box-shadow-xs      : $box-shadow-sm !default;
$modal-content-box-shadow-sm-up   : $box-shadow !default;

$modal-backdrop-bg        : $black !default;
$modal-backdrop-opacity   : .5 !default;
$modal-header-border-color: $border-color !default;
$modal-footer-border-color: $modal-header-border-color !default;
$modal-header-border-width: $modal-content-border-width !default;
$modal-footer-border-width: $modal-header-border-width !default;
$modal-header-padding-y   : $modal-inner-padding !default;
$modal-header-padding-x   : $modal-inner-padding !default;
$modal-header-padding     : $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

$modal-sm: 300px !default;
$modal-md: 500px !default;
$modal-lg: 800px !default;
$modal-xl: 1140px !default;

$modal-fade-transform : translate(0, -50px) !default;
$modal-show-transform : none !default;
$modal-transition     : transform .3s ease-out !default;
$modal-scale-transform: scale(1.02) !default;


// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y       : $spacer !default;
$alert-padding-x       : $spacer !default;
$alert-margin-bottom   : 1rem !default;
$alert-border-radius   : $border-radius !default;
$alert-link-font-weight: $font-weight-bold !default;
$alert-border-width    : $border-width !default;
$alert-icon-font-size  : 2rem !default;
$alert-link-color      : $white !default;

$alert-bg-level    : -10 !default !default;
$alert-border-level: -9 !default !default;
$alert-color-level : 10 !default !default;


// Progress bars

$progress-height              : 1rem !default;
$progress-font-size           : $font-size-base * .75 !default;
$progress-bg                  : $gray-200 !default;
$progress-border-radius       : $border-radius !default;
$progress-box-shadow          : $box-shadow-inset !default;
$progress-bar-color           : $white !default;
$progress-bar-bg              : $dark !default;
$progress-bar-animation-timing: 1s linear infinite !default;
$progress-bar-transition      : width .6s ease !default;

$progress-xl-size: .8rem !default;
$progress-lg-size: .6rem !default;
$progress-sm-size: .2rem !default;


// List group

$list-group-color        : $gray-700 !default;
$list-group-bg           : $white !default;
$list-group-border-color : rgba($black, .125) !default;
$list-group-border-width : $border-width !default;
$list-group-border-radius: $border-radius !default;

$list-group-sm-item-padding-y: .625rem !default;
$list-group-sm-item-padding-x: .875rem !default;

$list-group-item-padding-y    : math.div($spacer, 2) !default;
$list-group-item-padding-x    : $spacer !default;
$list-group-item-bg-level     : -9 !default;
$list-group-item-color-level  : 6 !default;
$list-group-item-border-radius: $border-radius !default;

$list-group-hover-bg           : $gray-100 !default;
$list-group-active-color       : $component-active-color !default;
$list-group-active-bg          : $component-active-bg !default;
$list-group-active-border-color: $list-group-active-bg !default;

$list-group-disabled-color: $gray-600 !default;
$list-group-disabled-bg   : $list-group-bg !default;

$list-group-action-color      : $gray-700 !default;
$list-group-action-hover-color: $list-group-action-color !default;

$list-group-action-active-color: $body-color !default;
$list-group-action-active-bg   : $gray-200 !default;

// Image thumbnails

$thumbnail-padding      : .25rem !default;
$thumbnail-bg           : $body-bg !default;
$thumbnail-border-width : $border-width !default;
$thumbnail-border-color : $gray-300 !default;
$thumbnail-border-radius: $border-radius !default;
$thumbnail-box-shadow   : $box-shadow-sm !default;


// Figures

$figure-caption-font-size: $small-font-size !default;
$figure-caption-color    : $gray-600 !default;


// Breadcrumbs

$breadcrumb-font-size     : null !default;
$breadcrumb-padding-y     : math.div($spacer, 2) !default;
$breadcrumb-padding-x     : $spacer !default;
$breadcrumb-item-padding-x: .5rem !default;
$breadcrumb-margin-bottom : 1rem !default;
$breadcrumb-bg            : $gray-200 !default;
$breadcrumb-divider-color : $gray-600 !default;
$breadcrumb-active-color  : $gray-600 !default;
$breadcrumb-divider       : quote("/") !default;
$breadcrumb-border-radius : $border-radius !default;

$breadcrumb-active-color       : $white !default;
$breadcrumb-divider-color      : $gray-700 !default;
$breadcrumb-item-color         : $gray-700 !default;
$breadcrumb-font-weight        : $font-weight-normal !default;
$breadcrumb-active-font-weight : $font-weight-normal !default;
$breadcrumb-item-light-color   : $white !default;
$breadcrumb-divider-light-color: $white !default;

// Carousel

$carousel-control-color        : $white !default;
$carousel-control-width        : 15% !default;
$carousel-control-opacity      : .5 !default;
$carousel-control-hover-opacity: .9 !default;
$carousel-control-transition   : opacity .15s ease !default;

$carousel-indicator-width          : 30px !default;
$carousel-indicator-height         : 3px !default;
$carousel-indicator-hit-area-height: 10px !default;
$carousel-indicator-spacer         : 3px !default;
$carousel-indicator-opacity        : .5 !default;
$carousel-indicator-active-bg      : $white !default;
$carousel-indicator-active-opacity : 1 !default;
$carousel-indicator-transition     : opacity .6s ease !default;

$carousel-caption-width    : 70% !default;
$carousel-caption-color    : $white !default;
$carousel-caption-padding-y: 1.25rem !default;
$carousel-caption-spacer   : 1.25rem !default;

$carousel-control-icon-width: 20px !default;

$carousel-control-prev-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'><path d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/></svg>") !default;
$carousel-control-next-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'><path d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/></svg>") !default;

$carousel-transition-duration: .6s !default;
$carousel-transition         : transform $carousel-transition-duration ease-in-out !default; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)


// Spinners

$spinner-width          : 2rem !default;
$spinner-height         : $spinner-width !default;
$spinner-border-width   : .25em !default;
$spinner-animation-speed: .75s !default;

$spinner-width-sm       : 1.2rem !default;
$spinner-height-sm      : $spinner-width-sm !default;
$spinner-border-width-sm: .2em !default;

// Datepicker

$datepicker-border-radius   : $border-radius-sm !default;
$datepicker-dropdown-padding: 20px 22px !default;

$datepicker-cell-transition      : $transition-base !default;
$datepicker-cell-hover-background: lighten($gray-400, 55%) !default;
$datepicker-cell-border-radius   : 50% !default;
$datepicker-cell-width           : 36px !default;
$datepicker-cell-height          : 36px !default;

$datepicker-disabled-cell-color   : $gray-300 !default;
$datepicker-disabled-old-new-color: $gray-500 !default;

$datepicker-header-cell-border-radius: $border-radius-sm !default;

$datepicker-active-color     : $white !default;
$datepicker-active-background: $secondary !default;
$datepicker-active-box-shadow: none !default;

$datepicker-range-background             : $secondary !default;
$datepicker-range-cell-focused-background: darken($datepicker-range-background, 5%) !default;
$datepicker-range-color                  : $white !default;
$datepicker-range-highlighted-bg         : $gray-200 !default;

$datepicker-dropdown-border: lighten($gray-400, 40%) !default;
$datepicker-dropdown-bg    : $white !default;
$datepicker-highlighted-bg : $datepicker-active-background !default;


// No UI Slider 
$noui-handle-width:               15px;
$noui-slider-height:              10px;
$noui-connect-bg:                 $secondary;
$noui-handle-bg:                  $light;
$noui-border-color:               #d1d7e0;
$noui-focus-shadow:               $input-btn-focus-box-shadow;
$noui-handle-border:              0;
$noui-handle-border-radius:       $border-radius;
$noui-origin-border-radius:       $circle-radius;


// Close

$close-bg         : transparent !default;
$close-hover-bg   : transparent !default;
$close-color      : rgba(0, 0, 0, .6) !default;
$close-hover-color: rgba(0, 0, 0, .9) !default;
$close-font-size  : $font-size-base * 1.5 !default;
$close-font-weight: $font-weight-bold !default;
$close-color      : $black !default;
$close-text-shadow: 0 1px 0 $white !default;



// Code

$code-font-size: $small-font-size !default;
$code-color    : $pink !default;

$kbd-padding-y: .2rem !default;
$kbd-padding-x: .4rem !default;
$kbd-font-size: $code-font-size !default;
$kbd-color    : $white !default;
$kbd-bg       : $gray-900 !default;

$pre-color: null !default;

// Avatars
$avatar-sm-y: 1.5rem !default;
$avatar-sm-x: 1.5rem !default;

$avatar-y: 2rem !default;
$avatar-x: 2rem !default;

$avatar-md-y: 3rem !default;
$avatar-md-x: 3rem !default;

$avatar-lg-y: 4.5rem !default;
$avatar-lg-x: 4.5rem !default;

$avatar-xl-y: 8rem !default;
$avatar-xl-x: 8rem !default;

//Sweet alerts

$swal2-white:         rgba(255, 255, 255, 0.55)  !default;
$swal2-black:         rgba($dark, 0.9)  !default;
$swal2-outline-color: $gray-500 !default;

// BACKDROP
$swal2-backdrop: rgba(255, 255, 255, 0.9) !default;
$swal2-backdrop-transition: background-color .1s !default;


// CONTAINER
$swal2-container-padding: .625em !default;

// BOX MODEL
$swal2-width: 28em !default;
$swal2-padding: 1.25em !default;
$swal2-border: none !default;
$swal2-border-radius: $border-radius !default;
$swal2-box-shadow: $box-shadow-sm !default;

// ANIMATIONS
$swal2-show-animation: swal2-show .3s !default;
$swal2-hide-animation: swal2-hide .15s forwards !default;

// BACKGROUND
$swal2-background: $swal2-white !default;

// TYPOGRAPHY
$swal2-font: $font-family-sans-serif !default;
$swal2-font-size: 1rem !default;

// HEADER
$swal2-header-padding: 0 1.8em !default;

// ICONS
$swal2-icon-size: 3em !default;
$swal2-icon-animations: true !default;
$swal2-icon-margin: 1.25em auto 1.875em !default;
$swal2-icon-zoom: null !default;
$swal2-success: $success !default;
$swal2-success-border: 0 !default;
$swal2-error: $danger !default;
$swal2-warning: $warning !default;
$swal2-info: $info !default;
$swal2-question: $gray-500 !default;
$swal2-icon-font-family: inherit !default;

// IMAGE
$swal2-image-margin: 1.25em auto !default;

// TITLE
$swal2-title-margin: 0 0 .4em !default;
$swal2-title-color: $gray-900 !default;
$swal2-title-font-size: 1.5em !default;

// CONTENT
$swal2-content-justify-content: center !default;
$swal2-content-margin: 0 !default;
$swal2-content-padding: 0 1.6em !default;
$swal2-content-color: $gray-600 !default;
$swal2-content-font-size: 1.125em !default;
$swal2-content-font-weight: normal !default;
$swal2-content-line-height: normal !default;
$swal2-content-text-align: center !default;
$swal2-content-word-wrap: break-word !default;

// INPUT
$swal2-input-margin: 1em auto !default;
$swal2-input-width: 100% !default;
$swal2-input-height: 2.625em !default;
$swal2-input-padding: 0 .75em !default;
$swal2-input-border: 1px solid lighten($swal2-black, 85) !default;
$swal2-input-border-radius: .1875em !default;
$swal2-input-box-shadow: inset 0 1px 1px rgba($swal2-black, .06) !default;
$swal2-input-focus-border: 1px solid #b4dbed !default;
$swal2-input-focus-outline: none !default;
$swal2-input-focus-box-shadow: 0 0 3px #c4e6f5 !default;
$swal2-input-font-size: 1.125em !default;
$swal2-input-background: inherit !default;
$swal2-input-color: inherit !default;
$swal2-input-transition: border-color .3s, box-shadow .3s !default;

// TEXTAREA SPECIFIC VARIABLES
$swal2-textarea-height: 6.75em !default;
$swal2-textarea-padding: .75em !default;

// VALIDATION MESSAGE
$swal2-validation-message-justify-content: center !default;
$swal2-validation-message-padding: .625em !default;
$swal2-validation-message-background: lighten($success, 94) !default;
$swal2-validation-message-color: lighten($swal2-black, 40) !default;
$swal2-validation-message-font-size: 1em !default;
$swal2-validation-message-font-weight: 300 !default;
$swal2-validation-message-icon-background: $swal2-error !default;
$swal2-validation-message-icon-color: $swal2-white !default;
$swal2-validation-message-icon-zoom: null !default;

// PROGRESS STEPS
$swal2-progress-steps-background: inherit !default;
$swal2-progress-steps-margin: 0 0 1.25em !default;
$swal2-progress-steps-padding: 0 !default;
$swal2-progress-steps-font-weight: 600 !default;
$swal2-progress-steps-distance: 2.5em !default;
$swal2-progress-step-width: 2em;
$swal2-progress-step-height: 2em;
$swal2-progress-step-border-radius: 2em;
$swal2-progress-step-background: #add8e6 !default;
$swal2-progress-step-color: $swal2-white !default;
$swal2-active-step-background: #3085d6 !default;
$swal2-active-step-color: $swal2-white !default;

// FOOTER
$swal2-footer-margin: 1.25em 0 0 !default;
$swal2-footer-padding: 1em 0 0 !default;
$swal2-footer-border-color: $white !default;
$swal2-footer-color: $gray-600 !default;
$swal2-footer-font-size: 1em !default;

// TIMER POGRESS BAR
$swal2-timer-progress-bar-height: .25em;
$swal2-timer-progress-bar-background: rgba($swal2-black, .2) !default;

// CLOSE BUTTON
$swal2-close-button-align-items: center !default;
$swal2-close-button-justify-content: center !default;
$swal2-close-button-width: 1.2em !default;
$swal2-close-button-height: 1.2em !default;
$swal2-close-button-line-height: 1.2 !default;
$swal2-close-button-position: absolute !default;
$swal2-close-button-gap: 0 !default;
$swal2-close-button-transition: color .1s ease-out !default;
$swal2-close-button-border: none !default;
$swal2-close-button-border-radius: 0 !default;
$swal2-close-button-outline: null !default;
$swal2-close-button-background: transparent !default;
$swal2-close-button-color: $danger !default;
$swal2-close-button-font-family: serif !default;
$swal2-close-button-font-size: 2.5em !default;

// CLOSE BUTTON:HOVER
$swal2-close-button-hover-transform: none !default;
$swal2-close-button-hover-color: $swal2-error !default;
$swal2-close-button-hover-background: transparent !default;

// ACTIONS
$swal2-actions-flex-wrap: wrap !default;
$swal2-actions-align-items: center !default;
$swal2-actions-justify-content: center !default;
$swal2-actions-width: 100% !default;
$swal2-actions-margin: 1.25em auto 0 !default;

// CONFIRM BUTTON
$swal2-confirm-button-border: 0 !default;
$swal2-confirm-button-border-radius: $border-radius !default;
$swal2-confirm-button-background-color: $primary !default;
$swal2-confirm-button-color: $swal2-white !default;
$swal2-confirm-button-font-size: 1.0625em !default;

// CANCEL BUTTON
$swal2-cancel-button-border: 0 !default;
$swal2-cancel-button-border-radius: .25em !default;
$swal2-cancel-button-background-color: $danger !default;
$swal2-cancel-button-color: $swal2-white !default;
$swal2-cancel-button-font-size: 1.0625em !default;

// COMMON VARIABLES FOR CONFIRM AND CANCEL BUTTONS
$swal2-button-darken-hover: rgba($dark, .1) !default;
$swal2-button-darken-active: rgba($dark, .2) !default;
$swal2-button-focus-outline: none !default;
$swal2-button-focus-background-color: null !default;
$swal2-button-focus-box-shadow: 0 0 0 1px $white, 0 0 0 3px $primary !default;

// TOASTS
$swal2-toast-show-animation: swal2-toast-show .5s !default;
$swal2-toast-hide-animation: swal2-toast-hide .1s forwards !default;
$swal2-toast-border: none !default;
$swal2-toast-box-shadow: 0 0 .625em #d9d9d9 !default;
$swal2-toast-background: $swal2-white !default;
$swal2-toast-close-button-width: .8em !default;
$swal2-toast-close-button-height: .8em !default;
$swal2-toast-close-button-line-height: .8 !default;
$swal2-toast-width: auto !default;
$swal2-toast-padding: .625em !default;
$swal2-toast-header-padding: 0 !default;
$swal2-toast-title-margin: 0 .6em !default;
$swal2-toast-title-font-size: 1em !default;
$swal2-toast-content-padding: 0 !default;
$swal2-toast-content-font-size: 1em !default;
$swal2-toast-input-font-size: 1em !default;
$swal2-toast-validation-font-size: 1em !default;
$swal2-toast-buttons-font-size: 1em !default;
$swal2-toast-button-focus-box-shadow: 0 0 0 1px $swal2-background, 0 0 0 3px $swal2-outline-color !default;
$swal2-toast-footer-margin: .5em 0 0 !default;
$swal2-toast-footer-padding: .5em 0 0 !default;
$swal2-toast-footer-font-size: .8em !default;

// Footer

$footer-link-font-size   : .85rem !default;
$footer-bg               : theme-color("secondary") !default;
$footer-color            : $gray-600 !default;
$footer-link-color       : $gray-600 !default;
$footer-link-hover-color : $gray-700 !default;
$footer-heading-color    : $gray-600 !default;
$footer-heading-font-size: $font-size-sm !default;
$padding-footer-sm       : .125rem !default;
$padding-footer          : .375rem !default;
$padding-footer-md       : .625rem !default;
$padding-footer-general  : .9375rem !default;
$padding-footer-big      : 1.5rem !default;
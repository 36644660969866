/* Step Indicators */
.steps-wrapper {
    padding: 20px 0;
}

.step-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.step-circle {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
    font-weight: bold;
    transition: all 0.3s ease;
    position: relative;
}

.step-circle.active {
    background: #25d366;
    color: white;
}

.step-line {
    flex: 1;
    height: 2px;
    background: #ddd;
    margin: 0 5px;
    position: relative;
}

.line-progress {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 0%;
    background: #6EC154;
    transition: width 0.5s ease;
}

.line-progress.active {
    width: 100%;
}

/* Form styling */
.form-group {
    margin-bottom: 1.5rem;
}

.invalid-feedback {
    color: #dc3545;
    font-size: 0.875em;
}

.is-invalid {
    border-color: #dc3545;
}

.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.support-link {
    color: #007bff;
    text-decoration: none;
}

.support-link:hover {
    text-decoration: underline;
}

.filter-white {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
}

.d-grid {
    display: grid;
}

.gap-3 {
    gap: 1rem;
}

.verified-header {
    background: #2F6055;
    color: white;
    border-radius: 10px;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.support-link {
    color: #007bff;
    text-decoration: underline;
    margin-left: 0.3rem;
}

.fb-button {
    background-color: #1877f2;
    border-color: #1877f2;
}

.fb-button:hover {
    background-color: #166fe5;
    border-color: #166fe5;
}

.migration-info {
    text-align: left;
}

.migration-info h5 {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}

.migration-info ul {
    margin-bottom: 1rem;
}

.migration-info ul li {
    margin-bottom: 0.5rem;
}
.custom-modal-wide {
    max-width: 5500px; /* Adjust the width as needed */
    width: 90%; /* Ensure it's responsive */
}

.custom-modal {
    max-width: 600px; /* Default width for other steps */
    width: 90%; /* Ensure it's responsive */
}


